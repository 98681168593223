a {
	font-weight: bold;

}

#lead-text {
	font-size: 1.15rem;
}

.ui.button.overview_toggle {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	font: inherit;
	color: #ccc;
}

.ui.button.overview_toggle>i {
	font-size: 2rem;
}

.ui.button.overview_toggle:hover {
	color: #fff;
}

.ui.button.overview_toggle>.icon.left {
	margin: 0;
	float: left;
}

.ui.button.overview_toggle>.icon.right {
	margin: 0;
	float: right;
}

.ui.overview_toggle button .icon {
	margin: 0
}

button.item {
	width: 100%;
}

.ui.nav.inverted.menu {
	background: #3c267b;
	border-bottom: 1px solid #3c267b;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item>a:not(.ui) {
	color: #fff;
}

.ui.nav .item>.knowledgebase .icon {
	color: #fff;
}

.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu a.item:hover {
	color: #ec8b00;
}

.ui.inverted.menu .dropdown.item>img.icon,
.ui.inverted.menu a.item>img.icon {
	opacity: 1
}

.ui.inverted.menu .dropdown.item:hover>img.icon,
.ui.inverted.menu a.item:hover>img.icon {
	opacity: 0;
}

.ui.inverted.menu .dropdown.item>img.icon.hover,
.ui.inverted.menu a.item>img.icon.hover {
	position: absolute;
	opacity: 0
}

.ui.inverted.menu .dropdown.item:hover>img.icon.hover,
.ui.inverted.menu a.item:hover>img.icon.hover {
	opacity: 1;
}

.ui.nav .item>.knowledgebase:focus .icon,
.ui.nav .item>.knowledgebase:hover .icon {
	color: #ec8b00;
}

.ui.menu .dropdown.item .menu {
	background: #3c267b;
}

.ui.menu .ui.dropdown .menu>.item {
	color: #fff !important;
}

.ui.menu .ui.dropdown .menu>.item:hover {
	color: #ec8b00 !important;
}

.ui.nav .ui.dropdown .menu>.item:focus,
.ui.nav .ui.dropdown .menu>.item:hover {
	background: rgba(0, 0, 0, 0) !important;
}

.ui.card a.workspace-name::after {
	color: #0a518c;
}


.portal.page.content {
	display: flex;
}

.portal.page .ui.card>.content.header {
	border-color: #0a518c;
	border-image-source: linear-gradient(to right, #ec8b00, #ec8b00);
	border-left: 0px solid;
	border-right: 0px solid;
	border-bottom: 0px solid;
	border-top: 5px solid !important;
}

.avatar-initials {
	color: #fff;
}

.overview {
	background: #432f88;
	display: flex;
}

.overview a {
	color: #ec8b00;
}

.overview a:hover {
	color: #ec8b00;
}

.overview-footnote {
	font-weight: normal;
	font-size: 0.8em;
	color: rgba(255, 255, 255, .8);
	font-style: italic;
}

.overview .ui.image {
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-right: 0px;
	margin-left: 2%;
	width: 95%;
	border-radius: 2.5%;
}

.overview .ui.image {
	margin: 2rem 0;
}

.overview .ui.image-container {
	width: 100%;
	text-align: right;
}

i.icon.eye-dropper:before {
	content: "\f1fb"
}

i.icon.folder:before {
	content: "\f07b"
}

i.icon.folder-plus:before {
	content: "\f65e"
}

i.icon.question-circle:before {
	content: "\f059"
}

i.icon.database:before {
	content: "\f1c0"
}

i.icon.project-diagram:before {
	content: "\f542"
}

i.icon.cog:before {
	content: "\f013"
}

i.icon.key:before {
	content: "\f084";
}

i.icon.graduation:before {
	content: "\f19d";
}

i.icon.life-ring:before {
	content: "\f1cd";
}

i.icon.desktop:before {
	content: "\f108";
}

.ui.purple.button {
	background-color: #432f88;
}

.ui.purple.button:hover {
	background-color: #432f88;
}

.ui.purple.button.gosh {
	background-color: #f5f5f5;
	color: #777;
	font-weight: normal;
	padding: 0.5rem;
	padding-bottom: 0.2rem;
	float: left;
	width: calc(33.3% - .5rem);
	margin-right: .5rem;
	min-width: 100px;
}

.ui.purple.button.gosh div:nth-child(2) {
	padding: .5rem;
	line-height: 1.2em;
}

.ui.purple.button.gosh:hover {
	background-color: #e5e5e5;
	color: #0a518c;
}

[data-position~=bottom][data-tooltip]:before {
	background: #ffffff;
}

[data-tooltip]:after,
[data-tooltip]:before {
	background: #ffffff;
}

[data-tooltip]:after {
	border: 0px solid #000000;
	color: #000;
}

/* Overwriting some semantic css  */
.portal .card .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
	margin: .2rem auto .5rem auto;
}

i.icon.loading {
	animation: icon-loading 2s linear infinite;
}

.portal .card .button {
	background: #432f88;
}

.portal .card .button .icon-inline {
	display: inline-block;
	height: 1rem;
	padding-right: 0.3rem;
}

.portal .card .button .icon-inline-ws {
	height: 1.6rem;
}

@media only screen and (min-width: 1200px) {
	.ui.container {
		width: auto;
	}
}

.portal .ui.card>.content>.need-help.description {
	padding: .5rem;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: rgb(255, 255, 255) !important;
}

.need-help.description>div {
	flex: 1 0 30%;
	background: none;
	margin: .5em;
	padding: 1em;
	border-radius: 3px;
	display: grid
}

.need-help.description>div>a {
	display: grid;
	color: #432f88;
}

.need-help.description>div>a>i {
	display: contents;
}

a:hover>i.icon.purple {
	color: #534394 !important;
}

i.purple.icon {
	color: #432f88 !important;
	transition: 0.1s;
}

@media screen and (min-width:1280px) {
	.need-help.description>div {
		flex: 1;
	}
}

.need-help.description>div>i {
	margin: auto;
	max-height: 60px
}

i.x-large.icon {
	line-height: 1;
	vertical-align: middle;
	font-size: 2.5em;
}

#portalLogo {
	width: 50px;
	;
}

#portalLogoHover {
	position: absolute;
	width: 50px;
	;
}

#header .ui.menu .container>a.item:first-child {
	width: 85px;
}

#header .ui.menu .container>a.item:first-child>#portalLogo {
	opacity: 1;
}

#header .ui.menu .container>a.item:first-child>#portalLogoHover {
	opacity: 0;
}

#header .ui.menu .container>a.item:first-child:hover>#portalLogo {
	opacity: 0;
}

#header .ui.menu .container>a.item:first-child:hover>#portalLogoHover {
	opacity: 1;
}

.aridhia-alert {
	margin-top: 4rem;
}

#announcement:not(:empty)+#body #sidebar {
	top: 127px;
}

#announcement:not(:empty)+#body .rsh-rtools-header .rsh-console-button {
	top: 159px;
}

#announcement {
	z-index: 99;
	position: relative;
}

#announcement .aridhia-alert {
	height: 70px;
	background: #135e72;
	position: relative;
	padding: 0.35714286rem;
}

#announcement .aridhia-alert .ui.message {
	box-sizing: border-box;
	align-items: flex-start;
	min-height: 70px;
	overflow: hidden;
}

#announcement .aridhia-alert .ui.message>.icon:not(.close) {
	color: #666564;
	font-size: 2.28571429rem;
	margin-top: 0.14285714rem;
}

#announcement .aridhia-alert .ui.message>.content {
	height: 2.5rem;
	max-width: calc(93.5%);
	margin: 0;
}

#announcement .aridhia-alert .ui.message>.content .header {
	color: #135e72;
	margin: 0;
}

#announcement .aridhia-alert .ui.message>.content .header .aridhia-date {
	display: inline-block;
	font-size: 0.92857143rem;
	margin-left: 0.92857143rem;
	font-style: italic;
	color: rgba(5, 5, 5, 0.5);
	border-left: 1px solid rgba(5, 5, 5, 0.33);
	padding-left: 0.85714286rem;
	white-space: nowrap;
	max-height: 1.42857143rem;
}

#announcement .aridhia-alert .ui.message>.content .text {
	height: 20px;
}

#announcement .aridhia-alert .ui.message>.content .text * {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1rem;
}

#announcement .aridhia-alert .ui.message>.content .text p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#announcement .aridhia-alert .ui.message>.content .text table {
	text-align: left;
}

#announcement .aridhia-alert .ui.message>.content .text a {
	color: #135e72;
	font-weight: 700;
}

#announcement .aridhia-alert .ui.message .aridhia-count {
	position: absolute;
	right: 35px;
	top: 11px;
	color: #135e72;
	font-size: 0.92857143rem;
	font-weight: 700;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu {
	position: absolute;
	right: 5px;
	top: 35px;
	background: 0;
	border: 0;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item {
	color: #135e72;
	font-family: 'Icons';
	padding: 5px;
	min-width: auto;
	opacity: 0.75;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item:hover {
	opacity: 1;
	background: 0;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item::before {
	display: none;
}

#announcement .aridhia-alert .ui.message:hover {
	max-height: calc(100vh - 100px);
}

#announcement .aridhia-alert .ui.message:hover>.content {
	height: auto;
}

#announcement .aridhia-alert .ui.message:hover>.content .header {
	height: auto;
	white-space: initial;
	overflow: initial;
}

#announcement .aridhia-alert .ui.message:hover>.content .text {
	height: auto;
	max-height: calc(100vh - 165px);
	overflow: auto;
}

#announcement .aridhia-alert .ui.message:hover>.content .text p {
	white-space: initial;
	overflow: initial;
}

.app-page-fullscreen #announcement {
	display: none;
}

#header {
	z-index: 100;
}

.powered-by-aridhia {
	max-width: 200px;
	opacity: 0.5;
	margin-bottom: 20px;
	float: right;
}

.form-input-stack-items>div {
	display: block !important;
}


.required {
	margin: -0.2em 0em 0em 0.2em;
	content: '*';
	color: #DB2828;
}